import axios, { AxiosInstance } from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const addAccessTokenInterceptor = (
  axiosInstance: AxiosInstance,
  showNotification: (
    message: string,
    description: string,
    type: "success" | "error",
  ) => void,
) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Check if XDEBUG_SESSION_START is already present in the URL
      if (
        process.env.REACT_APP_API_DEBUG === "true" &&
        config.url &&
        !config.url.includes("XDEBUG_SESSION_START")
      ) {
        const separator = config.url.includes("?") ? "&" : "?";
        config.url += `${separator}XDEBUG_SESSION_START=PHPSTORM`;
      }

      return config;
    },
    (error) => {
      showNotification("Request Error", "Failed to send request.", "error");
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        showNotification("Unauthorized", "Please log in again.", "error");
      } else if (error.response?.status === 400) {
        showNotification(
          "Bad Request",
          "There was a problem with your request.",
          "error",
        );
      } else {
        showNotification(
          "Error",
          error.response?.data?.message || "An error occurred.",
          "error",
        );
      }
      return Promise.reject(error);
    },
  );
};

export default axiosInstance;
