import { combineReducers } from "@reduxjs/toolkit";
import studentDetailsReducer from "../features/Registration/slices/studentDetails";
import checkoutReducer from "../features/Checkout/slices/checkoutSlice";
import getStartedReducer from "../features/Registration/slices/getStartedSlice";
import membershipReducer from "../features/Registration/slices/membershipSlice";
import upgradeDetailsReducer from "../features/Upgrade/slices/upgradeDetailsSlice";

const rootReducer = combineReducers({
  studentDetails: studentDetailsReducer,
  checkout: checkoutReducer,
  getStarted: getStartedReducer,
  membership: membershipReducer,
  upgradeDetails: upgradeDetailsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
