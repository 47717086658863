import React from "react";

interface NSCSSelectProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  options: { value: string; label: string }[];
  error?: string;
  className?: string;
  placeholder?: string; // Add placeholder prop
}

const NSCSSelect: React.FC<NSCSSelectProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  options,
  error,
  className,
  placeholder,
}) => {
  return (
    <div className={className}>
      <label
        className="block text-sm font-medium leading-6 text-gray-900"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="mt-2">
        <select
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
            ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 
            sm:text-sm sm:leading-6 ${error ? "ring-red-500" : ""}`}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
        >
          {/* Display placeholder as the first option */}
          {placeholder && (
            <option disabled value="">
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default NSCSSelect;
