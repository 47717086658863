import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Helmet } from "react-helmet-async";
import Header from "../../../../components/Header/Header";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/rootReducer";
import { useNavigate } from "react-router-dom";
import {
  fetchMembershipDetails,
  setMembership,
} from "../../slices/membershipSlice";
import { AppDispatch } from "../../../../app/store";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const MembershipSelection: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<string | null>(null);

  const {
    loading,
    memberships,
    selectedMembershipId,
    submitting,
    membershipPageHeader,
    membershipPageSubheader,
    error: sliceError,
  } = useSelector((state: RootState) => state.membership);

  const joinSessionUuid = localStorage.getItem("joinSessionUuid");

  useEffect(() => {
    if (joinSessionUuid) {
      dispatch(fetchMembershipDetails(joinSessionUuid));
    } else {
      setError("No active join session.");
    }
  }, [dispatch, joinSessionUuid]);

  useEffect(() => {
    if (sliceError) {
      setError(sliceError);
    }
  }, [sliceError]);

  const handleSelectTier = (tierId: number) => {
    if (!joinSessionUuid) {
      setError("No active join session.");
      return;
    }

    dispatch(
      setMembership(joinSessionUuid, tierId, () =>
        navigate("/registration/checkout"),
      ),
    );
  };

  const handleBack = () => {
    navigate("/registration/student-details");
  };

  return (
    <>
      <Helmet>
        <title>Registration - National Society of Collegiate Scholars</title>
      </Helmet>
      <div
        className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
        style={{
          backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
        }}
      >
        <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl">
          <Header />

          <div className="max-w-3xl mx-auto mt-5">
            <ProgressBar currentStep={2} />
          </div>

          <form className="relative isolate bg-white px-6 py-10 lg:px-8 max-w-3xl mx-auto">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
            >
              <div
                className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>

            {/* Heading Section */}
            <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
              <h2 className="text-base font-semibold leading-7 text-primary">
                {loading ? <Skeleton width={150} /> : membershipPageHeader}
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight text-accent sm:text-5xl">
                {loading ? <Skeleton width={350} /> : membershipPageSubheader}
              </p>
            </div>

            {/* Membership Tiers */}
            <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
              {loading
                ? Array.from({ length: 2 }).map((_, index) => (
                    <div
                      className="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 bg-white/60 sm:mx-8 lg:mx-0"
                      key={index}
                    >
                      <div className="h-6 w-32 mb-4">
                        <Skeleton height="100%" width="100%" />
                      </div>
                      <div className="mt-4 flex items-baseline gap-x-2">
                        <div className="h-10 w-20">
                          <Skeleton height="100%" width="100%" />
                        </div>
                        <div className="h-4 w-20">
                          <Skeleton height="100%" width="100%" />
                        </div>
                      </div>
                      <div className="mt-6 h-4 w-full">
                        <Skeleton height="100%" width="100%" />
                      </div>
                      {/* Fixed Bullet Points Skeleton Loaders */}
                      <ul
                        className="mt-8 space-y-3 leading-6 text-gray-600 sm:mt-10"
                        role="list"
                      >
                        {Array.from({ length: 3 }).map((__, i) => (
                          <li className="flex gap-x-3" key={i}>
                            <div className="h-6 w-5 flex-none">
                              <Skeleton circle height={24} width={24} />
                            </div>
                            <div className="flex-1">
                              <Skeleton height={16} />
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-8 h-10 w-full">
                        <Skeleton height="100%" width="100%" />
                      </div>
                    </div>
                  ))
                : memberships.map((tier) => (
                    <div
                      className="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 bg-white/60 sm:mx-8 lg:mx-0"
                      key={tier.id}
                    >
                      <h3 className="text-4xl font-semibold leading-7 text-primary">
                        {tier.name}
                      </h3>
                      <p className="mt-4 flex items-baseline gap-x-2">
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          $
                          {parseFloat(tier.price)
                            .toFixed(2)
                            .replace(/\.00$/, "")}
                        </span>

                        <span className="text-base text-gray-500">
                          /one-time fee
                        </span>
                      </p>
                      <ul
                        className="mt-8 space-y-3 leading-6 text-gray-600 sm:mt-10"
                        role="list"
                      >
                        {tier.features.map((feature) => (
                          <li className="flex gap-x-3" key={feature.id}>
                            <CheckIcon
                              aria-hidden="true"
                              className="h-6 w-5 flex-none text-primary"
                            />
                            {feature.description}
                          </li>
                        ))}
                      </ul>
                      <button
                        className={classNames(
                          "mt-8 block rounded-md px-3.5 py-2.5 text-center font-semibold",
                          selectedMembershipId === tier.id
                            ? "bg-primary text-white shadow hover:bg-primary-light"
                            : "text-primary ring-1 ring-inset ring-primary hover:ring-primary",
                        )}
                        onClick={() => handleSelectTier(tier.id)}
                        type="button"
                      >
                        {selectedMembershipId === tier.id
                          ? "Selected"
                          : "Choose this membership"}
                      </button>
                    </div>
                  ))}
            </div>

            {/* Error Message */}
            {error && (
              <div className="text-red-500 mt-2 text-center">{error}</div>
            )}

            {/* Back Button */}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                className="text-sm font-semibold leading-6 text-gray-900"
                disabled={submitting}
                onClick={handleBack}
                type="button"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MembershipSelection;
