import axios from "../axiosInstance";
import { SetMembershipRequest, SetMembershipResponse } from "./types";

export const setMembershipApi = async (
  uuid: string,
  requestData: SetMembershipRequest,
): Promise<SetMembershipResponse> => {
  const response = await axios.post<SetMembershipResponse>(
    `/api/public/join-session/${uuid}/set-membership`,
    requestData,
  );
  return response.data;
};
