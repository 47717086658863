import axios from "../axiosInstance";
import { GetMembershipDetailsResponse } from "./types";

export const getMembershipDetails = async (
  joinSessionUuid: string,
): Promise<GetMembershipDetailsResponse> => {
  const response = await axios.get<GetMembershipDetailsResponse>(
    `/api/public/join-session/${joinSessionUuid}/membership-details`,
  );
  return response.data;
};
