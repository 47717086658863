import { UploadTranscriptResponseData } from "./types";
import axiosInstance from "../axiosInstance";
import { AxiosProgressEvent } from "axios"; // Ensure AxiosProgressEvent is imported

export const uploadTranscript = async (
  joinSessionUuid: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void, // Use AxiosProgressEvent
): Promise<UploadTranscriptResponseData> => {
  const response = await axiosInstance.post<UploadTranscriptResponseData>(
    `/api/public/join-session/${joinSessionUuid}/upload-transcript`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    },
  );
  return response.data;
};
