import React, { useEffect, useRef } from "react";

interface ConfettiAnimationProps {
  trigger: boolean;
  duration?: number;
}

interface ConfettiParticle {
  x: number;
  y: number;
  r: number;
  d: number;
  color: string;
  tilt: number;
  tiltAngleIncremental: number;
  tiltAngle: number;
  draw: () => void;
}

const ConfettiAnimation: React.FC<ConfettiAnimationProps> = ({
  trigger,
  duration = 3000,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationRef = useRef<number | null>(null);
  const particlesRef = useRef<ConfettiParticle[]>([]);
  const stopGeneratingRef = useRef<boolean>(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    let W = window.innerWidth;
    let H = window.innerHeight;
    if (canvas) {
      canvas.width = W;
      canvas.height = H;
    }

    const createConfettiParticle = (): ConfettiParticle => ({
      x: Math.random() * W,
      y: Math.random() * H - H,
      r: Math.floor(Math.random() * (33 - 11 + 1) + 11),
      d: Math.random() * 150 + 11,
      color: possibleColors[Math.floor(Math.random() * possibleColors.length)],
      tilt: Math.floor(Math.random() * 33) - 11,
      tiltAngleIncremental: Math.random() * 0.07 + 0.05,
      tiltAngle: 0,
      draw() {
        if (context) {
          context.beginPath();
          context.lineWidth = this.r / 2;
          context.strokeStyle = this.color;
          context.moveTo(this.x + this.tilt + this.r / 3, this.y);
          context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
          context.stroke();
        }
      },
    });

    const draw = () => {
      if (!context) return;
      context.clearRect(0, 0, W, H);

      particlesRef.current.forEach((particle, i) => {
        // Update the y position for falling effect
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;

        // Gradually reduce the tilt to zero
        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.tilt *= 0.98; // Reduce the tilt effect over time to stabilize

        // Apply a gentle wobble effect, but limit it to prevent shaking
        particle.tilt = (Math.sin(particle.tiltAngle) * particle.r) / 4;

        // Remove the particle if it falls off the screen
        if (particle.y > H) {
          particlesRef.current.splice(i, 1);
        } else {
          particle.draw();
        }
      });

      if (particlesRef.current.length > 0 || !stopGeneratingRef.current) {
        animationRef.current = requestAnimationFrame(draw);
      } else {
        cancelAnimationFrame(animationRef.current as number);
      }
    };

    // Set custom colors (primary and secondary)
    const possibleColors = [
      "#981B1E", // Primary color
      "#A99268", // Secondary color
    ];

    const startConfetti = () => {
      particlesRef.current = [];
      stopGeneratingRef.current = false;

      const generateParticles = () => {
        if (!stopGeneratingRef.current) {
          for (let i = 0; i < 10; i++) {
            particlesRef.current.push(createConfettiParticle());
          }
          setTimeout(generateParticles, 100); // Continue generating at intervals
        }
      };

      generateParticles();
      draw();

      // Stop generating new particles after the specified duration
      setTimeout(() => {
        stopGeneratingRef.current = true;
      }, duration);
    };

    if (trigger) {
      startConfetti();
    } else {
      cancelAnimationFrame(animationRef.current as number);
      if (context) {
        context.clearRect(0, 0, W, H);
      }
    }

    window.addEventListener("resize", () => {
      W = window.innerWidth;
      H = window.innerHeight;
      if (canvas) {
        canvas.width = W;
        canvas.height = H;
      }
    });

    return () => {
      cancelAnimationFrame(animationRef.current as number);
      window.removeEventListener("resize", () => {
        W = window.innerWidth;
        H = window.innerHeight;
        if (canvas) {
          canvas.width = W;
          canvas.height = H;
        }
      });
    };
  }, [trigger, duration]);

  return (
    <canvas
      className="fixed top-0 left-0 w-full h-full pointer-events-none z-50"
      ref={canvasRef}
    ></canvas>
  );
};

export default ConfettiAnimation;
