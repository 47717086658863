import React from "react";

interface NSCSTextInputProps {
  id: string;
  name: string;
  label: string;
  type?: string;
  autoComplete?: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: string;
  className?: string; // Add this prop
}

const NSCSTextInput: React.FC<NSCSTextInputProps> = ({
  id,
  name,
  label,
  type = "text",
  autoComplete,
  value,
  onChange,
  error,
  className, // Destructure the new prop
}) => {
  return (
    <div className={className}>
      {" "}
      {/* Apply className here */}
      <label
        className="block text-sm font-medium leading-6 text-gray-900"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          autoComplete={autoComplete}
          className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm 
            ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
            focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
              error ? "ring-red-500" : ""
            }`}
          id={id}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
        />
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default NSCSTextInput;
