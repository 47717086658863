import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import { RootState } from "../../../app/rootReducer";
import {
  Donation,
  DonationOption,
  GetCheckoutDetailsResponse,
  Membership,
} from "../../../api/getCheckoutDetails/types";
import { getCheckoutDetails } from "../../../api/getCheckoutDetails/getCheckoutDetailsApi";
import {
  SetDonationRequest,
  SetDonationResponse,
} from "../../../api/setDonation/types";
import { setDonation } from "../../../api/setDonation/setDonationApi";
import { DeleteDonationResponse } from "../../../api/deleteDonation/types";
import { deleteDonation } from "../../../api/deleteDonation/deleteDonationApi";
import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
} from "../../../api/createPaymentIntent/types";
import { createPaymentIntent } from "../../../api/createPaymentIntent/createPaymentIntentApi";

// Define the state interface
interface CheckoutState {
  loading: boolean;
  error: string | null;
  membership: Membership | null;
  donationOptions: DonationOption[];
  donations: Donation[];
  subtotal: number;
  tax: number;
  totalDueToday: number;
  clientSecret: string | null;
  billingEmail: string;
}

const initialState: CheckoutState = {
  loading: false,
  error: null,
  membership: null,
  donationOptions: [],
  donations: [],
  subtotal: 0,
  tax: 0,
  totalDueToday: 0,
  clientSecret: null,
  billingEmail: "",
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setMembership: (state, action: PayloadAction<Membership | null>) => {
      state.membership = action.payload;
    },
    setDonationOptions: (state, action: PayloadAction<DonationOption[]>) => {
      state.donationOptions = action.payload;
    },
    setDonations: (state, action: PayloadAction<Donation[]>) => {
      state.donations = action.payload;
    },
    setSubtotal: (state, action: PayloadAction<number>) => {
      state.subtotal = action.payload;
    },
    setTax: (state, action: PayloadAction<number>) => {
      state.tax = action.payload;
    },
    setTotalDueToday: (state, action: PayloadAction<number>) => {
      state.totalDueToday = action.payload;
    },
    setBillingEmail: (state, action: PayloadAction<string>) => {
      state.billingEmail = action.payload;
    },
    setClientSecret: (state, action: PayloadAction<string | null>) => {
      state.clientSecret = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setMembership,
  setDonationOptions,
  setDonations,
  setSubtotal,
  setTax,
  setTotalDueToday,
  setClientSecret,
  setBillingEmail,
} = checkoutSlice.actions;

// Async thunk to fetch checkout details
export const fetchCheckoutDetails =
  (joinSessionUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: GetCheckoutDetailsResponse =
        await getCheckoutDetails(joinSessionUuid);
      const data = response.data;
      dispatch(setDonationOptions(data.donationOptions));
      dispatch(setDonations(data.donations));
      dispatch(setMembership(data.selectedMembership || null));
      dispatch(setBillingEmail(data.billingEmail));

      // Calculate subtotal, tax, totalDueToday
      const membershipPrice = data.selectedMembership
        ? parseFloat(data.selectedMembership.price)
        : 0;
      const donationTotal = data.donations.reduce(
        (sum, donation) => sum + parseFloat(donation.amount),
        0,
      );
      const subtotal = membershipPrice + donationTotal;
      const tax = 0; // Assuming tax is 0 for now
      const totalDueToday = subtotal + tax;

      dispatch(setSubtotal(subtotal));
      dispatch(setTax(tax));
      dispatch(setTotalDueToday(totalDueToday));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to fetch checkout details",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setDonationAmountAction =
  (joinSessionUuid: string, amount: number): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    //dispatch(setLoading(true));
    try {
      const requestData: SetDonationRequest = { amount };
      const response: SetDonationResponse = await setDonation(
        joinSessionUuid,
        requestData,
      );

      const newDonation: Donation = {
        uuid: response.data.donationUuid,
        amount: response.data.amount.toString(),
        date: new Date().toISOString(),
      };

      // Append the new donation to the existing donations
      const currentDonations = getState().checkout.donations;
      const updatedDonations = [...currentDonations, newDonation];
      dispatch(setDonations(updatedDonations));

      // Recalculate totals
      const state = getState().checkout;
      const membershipPrice = state.membership
        ? parseFloat(state.membership.price)
        : 0;
      const donationTotal = updatedDonations.reduce(
        (sum, donation) => sum + parseFloat(donation.amount),
        0,
      );
      const subtotal = membershipPrice + donationTotal;
      const tax = 0;
      const totalDueToday = subtotal + tax;

      dispatch(setSubtotal(subtotal));
      dispatch(setTax(tax));
      dispatch(setTotalDueToday(totalDueToday));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to set donation amount",
        ),
      );
    } finally {
      //dispatch(setLoading(false));
    }
  };

// Async thunk to delete donation
export const deleteDonationAction =
  (donationUuid: string): AppThunk =>
  async (dispatch: AppDispatch, getState) => {
    //dispatch(setLoading(true));
    try {
      const response: DeleteDonationResponse =
        await deleteDonation(donationUuid);
      if (response.status === "success") {
        // Remove the donation from the state
        const currentDonations = getState().checkout.donations;
        const updatedDonations = currentDonations.filter(
          (donation) => donation.uuid !== donationUuid,
        );
        dispatch(setDonations(updatedDonations));

        // Recalculate totals
        const state = getState().checkout;
        const membershipPrice = state.membership
          ? parseFloat(state.membership.price)
          : 0;
        const donationTotal = updatedDonations.reduce(
          (sum, donation) => sum + parseFloat(donation.amount),
          0,
        );
        const subtotal = membershipPrice + donationTotal;
        const tax = 0;
        const totalDueToday = subtotal + tax;

        dispatch(setSubtotal(subtotal));
        dispatch(setTax(tax));
        dispatch(setTotalDueToday(totalDueToday));
      } else {
        dispatch(setError("Failed to delete donation"));
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error ? error.message : "Failed to delete donation",
        ),
      );
    } finally {
      //dispatch(setLoading(false));
    }
  };

// Async thunk to create payment intent
export const createPaymentIntentAction =
  (joinSessionUuid: string, amount: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const requestData: CreatePaymentIntentRequest = { amount };
      const response: CreatePaymentIntentResponse = await createPaymentIntent(
        joinSessionUuid,
        requestData,
      );
      dispatch(setClientSecret(response.data.clientSecret));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to create payment intent",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

// Selector
export const selectCheckout = (state: RootState) => state.checkout;

export default checkoutSlice.reducer;
