import React, { useEffect } from "react";
import "./App.css";
import AppRoutes from "./app/AppRoutes";
import axiosInstance, { addAccessTokenInterceptor } from "./api/axiosInstance";
import { useNotification } from "./context/NotificationContext";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    addAccessTokenInterceptor(axiosInstance, showNotification);

    const joinSessionUuid = localStorage.getItem("joinSessionUuid");
    const currentPath = location.pathname;

    // Run the navigation logic only if the current route is not "/" or "/registration/get-started"
    if (
      !joinSessionUuid &&
      currentPath !== "/" &&
      currentPath !== "/registration/get-started"
    ) {
      navigate("/");
    }
  }, [showNotification, navigate, location.pathname]);

  return <AppRoutes />;
}

export default App;
