import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import { checkEligibility } from "../../../api/checkEligibility/checkEligibilityApi";
import { initiateJoin } from "../../../api/initiateJoin/initiateJoinApi";
import {
  CheckEligibilityRequest,
  CheckEligibilityResponse,
} from "../../../api/checkEligibility/types";
import {
  InitiateJoinRequest,
  InitiateJoinResponse,
} from "../../../api/initiateJoin/types";
import {
  InitiateSelfNominationRequest,
  InitiateSelfNominationResponse,
} from "../../../api/initiateSelfNomination/types";
import { initiateSelfNomination } from "../../../api/initiateSelfNomination/initiateSelfNominationApi";
import { initiateSchoolJoin } from "../../../api/initiateSchoolJoin/initiateSchoolJoinApi";
import {
  InitiateSchoolJoinRequest,
  InitiateSchoolJoinResponse,
} from "../../../api/initiateSchoolJoin/types";

// Define the state interface for registration
interface RegistrationState {
  inviteCode: string | null;
  loading: boolean;
  successMessage: string | null;
  error: string | null;
  submissionStatus: string | null;
  contactId: string | null;
  joinSessionUuid: string | null;
}

// Initial state for the registration slice
const initialState: RegistrationState = {
  inviteCode: null,
  loading: false,
  successMessage: null,
  error: null,
  submissionStatus: null,
  contactId: null,
  joinSessionUuid: null,
};

// Create the registration slice
const getStartedSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInviteCode: (state, action: PayloadAction<string | null>) => {
      state.inviteCode = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setSubmissionStatus: (state, action: PayloadAction<string | null>) => {
      state.submissionStatus = action.payload;
    },
    setContactId: (state, action: PayloadAction<string | null>) => {
      state.contactId = action.payload;
    },
    setJoinSessionUuid: (state, action: PayloadAction<string | null>) => {
      state.joinSessionUuid = action.payload;
    },
  },
});

// Export the actions
export const {
  setLoading,
  setInviteCode,
  setSuccessMessage,
  setError,
  setSubmissionStatus,
  setContactId,
  setJoinSessionUuid,
} = getStartedSlice.actions;

export const checkUserEligibility =
  (
    requestData: CheckEligibilityRequest,
    callback?: (contactId: string | undefined) => void,
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: CheckEligibilityResponse =
        await checkEligibility(requestData);

      if (response.data.status === "success") {
        dispatch(setInviteCode(requestData.invitationCode || null));
        dispatch(setSuccessMessage(response.data.message));
        dispatch(setContactId(response.data.contactId || null));
        dispatch(setSubmissionStatus(response.data.status));

        const contactId = response.data.contactId;

        if (callback) {
          callback(contactId);
        }
      } else {
        dispatch(
          setError(
            "Eligibility check failed. No contact found or invalid invite code.",
          ),
        );
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "An error occurred during the eligibility check.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const initiateJoinAction =
  (requestData: InitiateJoinRequest, callback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: InitiateJoinResponse = await initiateJoin(requestData);
      dispatch(setJoinSessionUuid(response.data.uuid));
      dispatch(setSuccessMessage(response.data.message));

      localStorage.setItem("joinSessionUuid", response.data.uuid);

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to initiate the join process.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const initiateSchoolJoinAction =
  (requestData: InitiateSchoolJoinRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: InitiateSchoolJoinResponse =
        await initiateSchoolJoin(requestData);
      dispatch(setJoinSessionUuid(response.data.uuid));
      dispatch(setSuccessMessage(response.data.message));

      localStorage.setItem("joinSessionUuid", response.data.uuid);
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to initiate the school join process.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const initiateSelfNominationAction =
  (requestData: InitiateSelfNominationRequest): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: InitiateSelfNominationResponse =
        await initiateSelfNomination(requestData);
      dispatch(setJoinSessionUuid(response.data.uuid));
      dispatch(setSuccessMessage(response.data.message));

      // Store joinSession UUID in localStorage
      localStorage.setItem("joinSessionUuid", response.data.uuid);

      // For self-nomination, no need to fetch HubSpot contact as they don't exist yet
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to initiate the self-nomination process.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

// Export the reducer
export default getStartedSlice.reducer;
