import axios from "../axiosInstance";
import {
  CreatePaymentIntentRequest,
  CreatePaymentIntentResponse,
} from "./types";

export const createPaymentIntent = async (
  joinSessionUuid: string,
  requestData: CreatePaymentIntentRequest,
): Promise<CreatePaymentIntentResponse> => {
  const response = await axios.post<CreatePaymentIntentResponse>(
    `/api/public/join-session/${joinSessionUuid}/create-payment-intent`,
    requestData,
  );
  return response.data;
};
