import axios from "../axiosInstance";
import { CheckEligibilityRequest, CheckEligibilityResponse } from "./types";

export const checkEligibility = async (
  requestData: CheckEligibilityRequest,
): Promise<CheckEligibilityResponse> => {
  const response = await axios.post<CheckEligibilityResponse>(
    `/api/public/eligibility/check`,
    requestData,
  );
  return response.data;
};
