// MobileOrderSummary.tsx
import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCheckout,
  deleteDonationAction,
} from "../../slices/checkoutSlice";
import ClassicMembershipIcon from "../../../../assets/images/classic_membership_icon.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AppDispatch } from "../../../../app/store";

interface MobileOrderSummaryProps {
  isVisible: boolean;
  closeSummary: () => void;
}

const MobileOrderSummary: React.FC<MobileOrderSummaryProps> = ({
  isVisible,
  closeSummary,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { membership, subtotal, tax, totalDueToday, donations } =
    useSelector(selectCheckout);

  const handleDeleteDonation = (donationUuid: string) => {
    dispatch(deleteDonationAction(donationUuid));
  };

  return (
    <Transition.Root as={Fragment} show={isVisible}>
      {/* Overlay for closing on outside click */}
      <Transition.Child
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          aria-hidden="true"
          className="fixed inset-0 bg-transparent z-40"
          onClick={closeSummary}
        />
      </Transition.Child>

      {/* Slide-Down Panel */}
      <Transition.Child
        as={Fragment}
        enter="transition ease-out duration-500"
        enterFrom="-translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave="transition ease-in duration-500"
        leaveFrom="translate-y-0 opacity-100"
        leaveTo="-translate-y-full opacity-0"
      >
        <div
          className="fixed top-0 left-0 right-0 bg-white p-6 shadow-md z-50 max-h-screen overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-black">Order Summary</h2>
            <button
              className="rounded-md bg-transparent p-1.5 text-black hover:text-gray-500 focus:outline-none"
              onClick={closeSummary}
              type="button"
            >
              <span className="sr-only">Close</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>

          {/* Membership Summary */}
          <div className="flex items-center mt-6 mb-4">
            <img
              alt="Membership Icon"
              className="w-8 h-8 mr-2"
              src={ClassicMembershipIcon}
            />
            <h2 className="text-lg font-medium text-black">
              {membership ? membership.name : "Membership"}
            </h2>
          </div>

          {/* Membership Price */}
          <div className="mb-4">
            <span className="text-2xl font-bold text-black">
              ${membership ? parseFloat(membership.price).toFixed(2) : "0.00"}
            </span>
            <span className="text-sm text-black ml-2">(once)</span>
          </div>

          {/* Full Price Breakdown */}
          <div className="border-t border-gray-300 pt-4">
            {/* Membership Line Item */}
            <div className="flex justify-between mb-2">
              <span className="text-black">
                {membership?.name || "Membership"}
              </span>
              <span className="text-black">
                ${membership ? parseFloat(membership.price).toFixed(2) : "0.00"}
              </span>
            </div>

            {/* Donation Line Items */}
            {donations &&
              donations.length > 0 &&
              donations.map((donation, index) => (
                <div className="flex justify-between mb-2" key={donation.uuid}>
                  <span className="text-black flex items-center">
                    Donation {index + 1}
                    <button
                      className="ml-2 text-blue-500 underline font-bold"
                      onClick={() => handleDeleteDonation(donation.uuid)}
                    >
                      Remove
                    </button>
                  </span>
                  <span className="text-black">
                    ${parseFloat(donation.amount).toFixed(2)}
                  </span>
                </div>
              ))}

            {/* Subtotal */}
            <div className="flex justify-between mb-2">
              <span className="text-black">Subtotal:</span>
              <span className="text-black">${subtotal.toFixed(2)}</span>
            </div>

            {/* Tax */}
            <div className="flex justify-between mb-2">
              <span className="text-black">Tax:</span>
              <span className="text-black">${tax.toFixed(2)}</span>
            </div>

            {/* Total Due Today */}
            <div className="flex justify-between font-bold text-black">
              <span>Total Due Today:</span>
              <span>${totalDueToday.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default MobileOrderSummary;
