import React from "react";
import styles from "./Loader.module.css";

const Loader: React.FC = () => {
  const loaderColor = "#981B1E";

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className={styles.growingBars}>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
        <div
          className={styles.bar}
          style={{ backgroundColor: loaderColor }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
