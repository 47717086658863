import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import { getMembershipDetails } from "../../../api/getMembershipDetails/getMembershipDetailsApi";
import { setMembershipApi } from "../../../api/setMembership/setMembershipApi";
import {
  GetMembershipDetailsResponse,
  Membership,
} from "../../../api/getMembershipDetails/types";
import {
  SetMembershipRequest,
  SetMembershipResponse,
} from "../../../api/setMembership/types";

interface MembershipState {
  loading: boolean;
  submitting: boolean;
  memberships: Membership[];
  selectedMembershipId: number | null;
  membershipPageHeader: string | null;
  membershipPageSubheader: string | null;
  error: string | null;
}

const initialState: MembershipState = {
  loading: false,
  submitting: false,
  memberships: [],
  selectedMembershipId: null,
  membershipPageHeader: null,
  membershipPageSubheader: null,
  error: null,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.submitting = action.payload;
    },
    setMemberships: (state, action: PayloadAction<Membership[]>) => {
      state.memberships = action.payload;
    },
    setSelectedMembershipId: (state, action: PayloadAction<number | null>) => {
      state.selectedMembershipId = action.payload;
    },
    setMembershipPageHeader: (state, action: PayloadAction<string | null>) => {
      state.membershipPageHeader = action.payload;
    },
    setMembershipPageSubheader: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.membershipPageSubheader = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    resetMessages: (state) => {
      state.error = null;
    },
  },
});

export const {
  setLoading,
  setSubmitting,
  setMemberships,
  setSelectedMembershipId,
  setMembershipPageHeader,
  setMembershipPageSubheader,
  setError,
  resetMessages,
} = membershipSlice.actions;

export const fetchMembershipDetails =
  (joinSessionUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    dispatch(resetMessages());

    try {
      const response: GetMembershipDetailsResponse =
        await getMembershipDetails(joinSessionUuid);

      dispatch(setMemberships(response.data.memberships));
      dispatch(setSelectedMembershipId(response.data.selectedMembershipId));
      dispatch(setMembershipPageHeader(response.data.membershipPageHeader));
      dispatch(
        setMembershipPageSubheader(response.data.membershipPageSubheader),
      );
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to load membership details.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setMembership =
  (uuid: string, membershipId: number, callback?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setSubmitting(true));
    dispatch(resetMessages());

    try {
      dispatch(setSelectedMembershipId(membershipId));

      const requestData: SetMembershipRequest = { membershipId };
      const response: SetMembershipResponse = await setMembershipApi(
        uuid,
        requestData,
      );

      if (response.data.status === "success") {
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "An error occurred while setting membership.",
        ),
      );
    } finally {
      dispatch(setSubmitting(false));
    }
  };

export default membershipSlice.reducer;
