import axios from "../axiosInstance";
import { GetCheckoutDetailsResponse } from "./types";

export const getCheckoutDetails = async (
  joinSessionUuid: string,
): Promise<GetCheckoutDetailsResponse> => {
  const response = await axios.get<GetCheckoutDetailsResponse>(
    `/api/public/join-session/${joinSessionUuid}/checkout-details`,
  );
  return response.data;
};
