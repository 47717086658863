import axios from "../axiosInstance";
import { InitiateSchoolJoinRequest, InitiateSchoolJoinResponse } from "./types";

export const initiateSchoolJoin = async (
  requestData: InitiateSchoolJoinRequest,
): Promise<InitiateSchoolJoinResponse> => {
  const response = await axios.post<InitiateSchoolJoinResponse>(
    `/api/public/join/initiate-school`,
    requestData,
  );
  return response.data;
};
