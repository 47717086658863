import React from "react";

const EligibilityDescription: React.FC = () => {
  return (
    <>
      <p className="text-gray-900 mb-4">
        To be eligible for membership, you must:
      </p>
      <ul className="list-disc list-inside text-gray-700 space-y-2">
        <li>
          Have at least a 3.4 cumulative grade point average (GPA) on a 4.0
          scale
        </li>
        <li>Be enrolled as a first- or second-year undergraduate student</li>
        <li>
          Have completed at least one term of full-time study but no more than
          half the degree requirements
        </li>
        <li>
          Submit a recent transcript for verification at the end of the join
          process
        </li>
        <li>
          If not eligible, a $5 processing fee will be deducted from the
          membership refund
        </li>
      </ul>
    </>
  );
};

export default EligibilityDescription;
