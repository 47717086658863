import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../app/store";
import {
  ContactByJoinSession,
  GetContactByJoinSessionResponse,
} from "../../../api/getHubspotContactByJoinSession/types";
import { getContactByJoinSession } from "../../../api/getHubspotContactByJoinSession/getHubspotContactByJoinSessionApi";
import {
  SubmitStudentDetailsRequest,
  SubmitStudentDetailsResponse,
} from "../../../api/submitStudentDetails/types";
import { submitStudentDetails } from "../../../api/submitStudentDetails/submitStudentDetailsApi";

// Define the state interface for registration
interface RegistrationState {
  loading: boolean;
  submitting: boolean; // New state for form submission
  successMessage: string | null;
  error: string | null;
  hubspotContact: ContactByJoinSession | null;
}

// Initial state for the registration slice
const initialState: RegistrationState = {
  loading: false,
  submitting: false, // Initialize submitting
  successMessage: null,
  error: null,
  hubspotContact: null,
};

// Create the studentDetails slice
const studentDetails = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSubmitting: (state, action: PayloadAction<boolean>) => {
      state.submitting = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setHubspotContact: (
      state,
      action: PayloadAction<ContactByJoinSession | null>,
    ) => {
      state.hubspotContact = action.payload;
    },
  },
});

// Export the actions
export const {
  setLoading,
  setSubmitting, // Export setSubmitting
  setSuccessMessage,
  setError,
  setHubspotContact,
} = studentDetails.actions;

// Thunks

/**
 * Thunk to fetch HubSpot contact by join session UUID.
 */
export const fetchHubspotContact =
  (joinSessionUuid: string): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {
      const response: GetContactByJoinSessionResponse =
        await getContactByJoinSession(joinSessionUuid);
      dispatch(setHubspotContact(response.data));
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "Failed to fetch HubSpot contact.",
        ),
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

/**
 * Thunk to submit student details.
 * Accepts a callback function to handle actions upon successful submission.
 */
export const submitStudentDetailsAction =
  (
    joinSessionUuid: string,
    requestData: SubmitStudentDetailsRequest,
    onSuccess: () => void,
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setSubmitting(true));

    try {
      const response: SubmitStudentDetailsResponse = await submitStudentDetails(
        joinSessionUuid,
        requestData,
      );

      if (response.success) {
        dispatch(setSuccessMessage("Student details submitted successfully!"));
        onSuccess();
      } else {
        dispatch(setError("Failed to submit student details."));
      }
    } catch (error) {
      dispatch(
        setError(
          error instanceof Error
            ? error.message
            : "An error occurred while submitting student details.",
        ),
      );
    } finally {
      dispatch(setSubmitting(false));
    }
  };

// Export the reducer
export default studentDetails.reducer;
