import axios from "../axiosInstance";
import { PostPaymentHandlerRequest, PostPaymentHandlerResponse } from "./types";

export const postPaymentHandlerApi = async (
  uuid: string,
  requestData: PostPaymentHandlerRequest,
): Promise<PostPaymentHandlerResponse> => {
  const response = await axios.post<PostPaymentHandlerResponse>(
    `/api/public/join-session/${uuid}/post-payment-handler`,
    requestData,
  );
  return response.data;
};
