import axios from "../axiosInstance";
import { GetUpgradeDetailsResponse } from "./types";

export const getUpgradeDetails = async (
  joinSessionUuid: string,
): Promise<GetUpgradeDetailsResponse> => {
  const response = await axios.get<GetUpgradeDetailsResponse>(
    `/api/public/join-session/${joinSessionUuid}/upgrade-details`,
  );
  return response.data;
};
