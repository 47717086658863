import React from "react";
import StudentDetailsForm from "../StudentDetailsForm/StudentDetailsForm";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";
import Header from "../../../../components/Header/Header";
import { Helmet } from "react-helmet-async";

const StudentDetails: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Registration - National Society of Collegiate Scholars</title>
      </Helmet>
      <div
        className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
        style={{
          backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
        }}
      >
        <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl">
          <Header />
          <div className="max-w-3xl mx-auto mt-5">
            <ProgressBar currentStep={1} />
          </div>
          <StudentDetailsForm />
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
