import axios from "../axiosInstance";
import { UpgradeChargeRequest, UpgradeChargeResponse } from "./types";

export const createUpgradeCharge = async (
  joinSessionUuid: string,
  requestData: UpgradeChargeRequest,
): Promise<UpgradeChargeResponse> => {
  const response = await axios.post<UpgradeChargeResponse>(
    `/api/public/join-session/${joinSessionUuid}/create-upgrade-charge`,
    requestData,
  );
  return response.data;
};
