// CongratulationsPage.jsx

import React from "react";
import { Link } from "react-router-dom";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  HeartIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import Header from "../../../components/Header/Header";

const features = [
  {
    name: "A confirmation email confirming your membership.",
    icon: PencilSquareIcon,
  },
  {
    name: "Instructions on how to download your personalized membership certificate and a press release announcing your membership in NSCS.",
    icon: ChatBubbleOvalLeftEllipsisIcon,
  },
  {
    name: "Welcome emails with important information on how to use your new member benefits including how to register for the member portal.",
    icon: HeartIcon,
  },
];

const Congratulations = () => {
  return (
    <div className="bg-white">
      {/* Include the custom Header component */}
      <Header />

      <div className="py-20 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* Main heading */}
          <div className="text-center">
            <h1 className="text-4xl font-semibold tracking-tight text-primary sm:text-5xl">
              Congratulations, Welcome to NSCS
            </h1>
            <p className="mt-6 text-lg leading-8 text-fontColor">
              Be on the lookout for the following important emails from us:
            </p>
          </div>

          {/* Features list */}
          <div className="mt-16 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div className="text-center" key={index}>
                <div className="mb-4 flex h-12 w-12 mx-auto items-center justify-center rounded-full bg-secondary">
                  <feature.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </div>
                <p className="text-base leading-7 text-darkGray">
                  {feature.name}
                </p>
              </div>
            ))}
          </div>

          {/* Two-column section */}
          <div className="mt-24 grid grid-cols-1 gap-16 lg:grid-cols-2">
            {/* Shop Box */}
            <div className="bg-secondary-light p-8 rounded-lg">
              <h2 className="text-2xl font-semibold text-white">
                SHOW YOUR <br className="sm:hidden" /> NSCS PRIDE
              </h2>
              <p className="mt-4 text-white">
                Visit the NSCS Online Store to purchase t-shirts, apparel, and
                more. Enter code "newmember" for 15% off your purchase.
              </p>
              <a
                className="mt-6 inline-block rounded-md bg-primary px-6 py-3 text-base font-medium text-white hover:bg-primary-dark"
                href="https://store.nscs.org"
              >
                Shop Now
              </a>
            </div>

            {/* Questions Box */}
            <div className="bg-primary p-8 rounded-lg">
              <h2 className="text-2xl font-semibold text-white">
                STILL HAVE QUESTIONS?
              </h2>
              <div className="mt-4 space-y-2">
                <p>
                  <a
                    className="text-white hover:underline"
                    href="mailto:nscs@nscs.org"
                  >
                    <i className="fas fa-envelope mr-2"></i>nscs@nscs.org
                  </a>
                </p>
                <p>
                  <a
                    className="text-white hover:underline"
                    href="tel:202-265-9000"
                  >
                    <i className="fas fa-phone mr-2"></i>202-265-9000
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
