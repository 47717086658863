import axios from "../axiosInstance";
import {
  InitiateSelfNominationRequest,
  InitiateSelfNominationResponse,
} from "./types";

export const initiateSelfNomination = async (
  requestData: InitiateSelfNominationRequest,
): Promise<InitiateSelfNominationResponse> => {
  const response = await axios.post<InitiateSelfNominationResponse>(
    `/api/public/join/initiate/self-nomination`,
    requestData,
  );
  return response.data;
};
