import React from "react";
import { Link } from "react-router-dom";
import NSCSLogoColor from "../../assets/images/nscs_logo_color.svg";
import TOLALogoColor from "../../assets/images/tola_logo_color.svg";

const Header: React.FC = () => {
  const isTola = process.env.REACT_APP_TOLA === "true";

  return (
    <nav className="py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img
                alt={isTola ? "TOLA Logo" : "NSCS Logo"}
                className={isTola ? "h-20 w-auto" : "h-12 w-auto"}
                src={isTola ? TOLALogoColor : NSCSLogoColor}
              />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
