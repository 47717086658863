import axios from "../axiosInstance";
import { GetContactByJoinSessionResponse } from "./types";

export const getContactByJoinSession = async (
  joinSessionUuid: string,
): Promise<GetContactByJoinSessionResponse> => {
  const response = await axios.get<GetContactByJoinSessionResponse>(
    `/api/public/join-session/${joinSessionUuid}/hubspot-contact`,
  );
  return response.data;
};
