import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import countryList from "react-select-country-list";
import { allCountries } from "country-region-data";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import { RootState } from "../../../app/rootReducer";
import {
  fetchHubspotContact,
  submitStudentDetailsAction,
} from "../slices/studentDetails";
import { SubmitStudentDetailsRequest } from "../../../api/submitStudentDetails/types";

interface SchoolOption {
  label: string;
  value: string;
}

interface FormValueTypes {
  firstName: string;
  middleName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string; // Shorthand value like 'MN'
  zipcode: string;
  country: string;
  personalEmail: string;
  schoolEmail: string;
  school: SchoolOption | null; // Optional if isTOLA is true
  agreeToPolicies: boolean;
  // New fields for TOLA
  highSchoolName: string;
  highSchoolGraduationYear: string;
  highSchoolGpa: string;
}

interface UseRegistrationReturnType {
  formValues: FormValueTypes;
  validationErrors: { [key: string]: string };
  stateOptions: { value: string; label: string }[];
  countryOptions: { value: string; label: string }[];
  graduationYears: { value: string; label: string }[]; // For TOLA
  hasErrors: boolean;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleSchoolChange: (selectedSchool: SchoolOption | null) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
  submitting: boolean;
}

const useRegistration = (): UseRegistrationReturnType => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isTOLA = process.env.REACT_APP_TOLA === "true"; // Check if TOLA is true

  const hubspotContact = useSelector(
    (state: RootState) => state.studentDetails.hubspotContact,
  );

  const loading = useSelector(
    (state: RootState) => state.studentDetails.loading,
  );
  const submitting = useSelector(
    (state: RootState) => state.studentDetails.submitting,
  );

  const [formValues, setFormValues] = useState<FormValueTypes>({
    firstName: "",
    middleName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "", // Initialize as empty string
    zipcode: "",
    country: "US", // Default country to United States
    personalEmail: "",
    schoolEmail: "",
    school: null,
    agreeToPolicies: false,
    // Initialize new TOLA fields
    highSchoolName: "",
    highSchoolGraduationYear: "",
    highSchoolGpa: "",
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const joinSessionUuid = localStorage.getItem("joinSessionUuid");

  const countryOptions = useMemo(
    () => [{ value: "", label: "Select Country" }, ...countryList().getData()],
    [],
  );

  const [stateOptions, setStateOptions] = useState<
    { value: string; label: string }[]
  >([]);

  // Generate graduation years for TOLA
  const currentYear = new Date().getFullYear();
  const graduationYears = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => currentYear + i).map((year) => ({
        value: year.toString(),
        label: year.toString(),
      })),
    [currentYear],
  );

  useEffect(() => {
    if (joinSessionUuid) {
      dispatch(fetchHubspotContact(joinSessionUuid));
    }
  }, [dispatch, joinSessionUuid]);

  useEffect(() => {
    if (hubspotContact) {
      let school: SchoolOption | null = null;

      if (hubspotContact.schoolId && hubspotContact.schoolName) {
        school = {
          label: hubspotContact.schoolName,
          value: hubspotContact.schoolId,
        };
      }

      const countryCode = hubspotContact.country
        ? countryList().getValue(hubspotContact.country) || ""
        : "US"; // Default to "US" if no country is provided

      let regionCode = "";
      if (countryCode && hubspotContact.state) {
        const countryData = allCountries.find((c) => c[1] === countryCode);
        if (countryData) {
          const regions = countryData[2];
          const region = regions.find(
            (r) =>
              r[0].toLowerCase() === hubspotContact.state?.toLowerCase() ||
              r[1].toLowerCase() === hubspotContact.state?.toLowerCase(),
          );
          regionCode = region ? region[1] : hubspotContact.state || ""; // Use shorthand code
        } else {
          regionCode = hubspotContact.state || "";
        }
      }

      setFormValues((prevValues) => ({
        ...prevValues,
        firstName: hubspotContact.firstName || "",
        middleName: hubspotContact.middleName || "",
        lastName: hubspotContact.lastName || "",
        address1: hubspotContact.address || "",
        address2: hubspotContact.address2 || "",
        city: hubspotContact.city || "",
        state: regionCode, // Shorthand code (e.g., "MN")
        zipcode: hubspotContact.zip || "",
        country: countryCode,
        personalEmail:
          hubspotContact.personalEmail || hubspotContact.email || "",
        schoolEmail: hubspotContact.schoolEmail || "",
        school: school,
        agreeToPolicies: hubspotContact.acceptedPrivacyPolicy || false,
        // Set new TOLA fields from HubSpot contact if available
        highSchoolName: hubspotContact.highSchoolName || "",
        highSchoolGraduationYear: hubspotContact.highSchoolGraduationYear || "",
        highSchoolGpa: hubspotContact.highSchoolGpa || "",
      }));
      setValidationErrors({});
    }
  }, [hubspotContact]);

  useEffect(() => {
    if (formValues.country) {
      const countryData = allCountries.find((c) => c[1] === formValues.country);
      if (countryData && countryData[2] && countryData[2].length > 0) {
        // Map state options with shorthand for value and full name for label
        const options = countryData[2].map((region) => ({
          value: region[1], // Use the shorthand code (e.g., "MN")
          label: region[0], // Use the full name (e.g., "Minnesota")
        }));
        setStateOptions([
          { value: "", label: "Select State/Province" },
          ...options,
        ]);
      } else {
        setStateOptions([]);
      }
    } else {
      setStateOptions([]);
    }
    setFormValues((prevValues) => ({ ...prevValues, state: "" }));
  }, [formValues.country]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target;
    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
    setValidationErrors((prevErrors) => {
      const { [name]: _, ...rest } = prevErrors;
      return rest;
    });
  };

  const handleSchoolChange = (selectedSchool: SchoolOption | null) => {
    setFormValues({ ...formValues, school: selectedSchool });
    setValidationErrors((prevErrors) => {
      const { school, ...rest } = prevErrors;
      return rest;
    });
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formValues.firstName.trim())
      errors.firstName = "First name is required.";
    if (!formValues.lastName.trim()) errors.lastName = "Last name is required.";
    if (!formValues.address1.trim())
      errors.address1 = "Address Line 1 is required.";
    if (!formValues.city.trim()) errors.city = "City is required.";
    if (!formValues.zipcode.trim()) errors.zipcode = "Zipcode is required.";
    if (!formValues.country) errors.country = "Country is required.";
    if (stateOptions.length > 0 && !formValues.state)
      errors.state = "State/Province is required.";
    if (!formValues.personalEmail.trim()) {
      errors.personalEmail = "Personal email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formValues.personalEmail)) {
      errors.personalEmail = "Invalid email address.";
    }
    if (!formValues.agreeToPolicies)
      errors.agreeToPolicies = "You must agree to the policies.";

    if (isTOLA) {
      // Validate TOLA-specific fields
      if (!formValues.highSchoolName.trim())
        errors.highSchoolName = "High School Name is required.";
      if (!formValues.highSchoolGraduationYear)
        errors.highSchoolGraduationYear = "Graduation Year is required.";
      if (!formValues.highSchoolGpa.trim())
        errors.highSchoolGpa = "High School GPA is required.";
    } else {
      if (!formValues.school) errors.school = "School/University is required.";
    }

    return errors;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const submitData: SubmitStudentDetailsRequest = {
      firstName: formValues.firstName,
      middleName: formValues.middleName,
      lastName: formValues.lastName,
      address1: formValues.address1,
      address2: formValues.address2,
      city: formValues.city,
      state: formValues.state, // Submit the shorthand state code
      zipcode: formValues.zipcode,
      country: countryList().getLabel(formValues.country) || "",
      personalEmail: formValues.personalEmail,
      schoolEmail: formValues.schoolEmail,
      agreeToPolicies: formValues.agreeToPolicies,
      isTOLA,
    };

    if (isTOLA) {
      // Include TOLA-specific fields
      submitData.highSchoolName = formValues.highSchoolName;
      submitData.highSchoolGraduationYear = formValues.highSchoolGraduationYear;
      submitData.highSchoolGpa = formValues.highSchoolGpa;
    } else {
      submitData.school = formValues.school
        ? parseInt(formValues.school.value, 10)
        : 0;
    }

    if (joinSessionUuid) {
      dispatch(
        submitStudentDetailsAction(joinSessionUuid, submitData, () => {
          navigate("/registration/membership");
        }),
      );
    }
  };

  const hasErrors = Object.values(validationErrors).some(
    (error) => error !== "",
  );

  return {
    formValues,
    validationErrors,
    stateOptions,
    countryOptions,
    graduationYears,
    hasErrors,
    handleInputChange,
    handleSchoolChange,
    handleSubmit,
    loading,
    submitting,
  };
};

export default useRegistration;
