import axios from "../axiosInstance";
import { InitiateJoinRequest, InitiateJoinResponse } from "./types";

export const initiateJoin = async (
  requestData: InitiateJoinRequest,
): Promise<InitiateJoinResponse> => {
  const response = await axios.post<InitiateJoinResponse>(
    `/api/public/join/initiate`,
    requestData,
  );
  return response.data;
};
