import React from "react";

interface Step {
  id: string;
  name: string;
  href: string;
}

interface ProgressBarProps {
  currentStep: number;
}

const steps: Step[] = [
  {
    id: "Step 1",
    name: "Student Details",
    href: "/registration/student-details",
  },
  { id: "Step 2", name: "Membership", href: "/registration/membership" },
  { id: "Step 3", name: "Checkout", href: "#" },
];

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep }) => {
  return (
    <nav aria-label="Progress">
      <ol className="flex space-x-2 md:space-x-8 md:space-y-0" role="list">
        {steps.map((step, index) => {
          // Determine step status
          let status;
          if (index + 1 < currentStep) {
            status = "complete";
          } else if (index + 1 === currentStep) {
            status = "current";
          } else {
            status = "upcoming";
          }

          return (
            <li className="flex-1" key={step.name}>
              {status === "complete" ? (
                <a
                  className="group flex flex-col border-primary py-2 pl-4 hover:border-primary border-l-0 border-t-4 md:pb-0 md:pl-0 md:pt-4"
                  href={step.href}
                >
                  <span className="text-sm font-medium text-primary group-hover:text-primary">
                    {step.id}
                  </span>
                  <span className="text-sm font-medium">{step.name}</span>
                </a>
              ) : (
                <span
                  aria-current={status === "current" ? "step" : undefined}
                  className={`flex flex-col py-2 pl-4 border-l-0 border-t-4 md:pb-0 md:pl-0 md:pt-4 ${
                    status === "current"
                      ? "border-primary text-primary"
                      : "border-gray-200 text-gray-500"
                  }`}
                >
                  <span className="text-sm font-medium">{step.id}</span>
                  <span className="text-sm font-medium">{step.name}</span>
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ProgressBar;
