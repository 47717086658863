import axios from "../axiosInstance";
import { DeleteDonationResponse } from "./types";

export const deleteDonation = async (
  donationUuid: string,
): Promise<DeleteDonationResponse> => {
  const response = await axios.delete<DeleteDonationResponse>(
    `/api/public/donation/${donationUuid}`,
  );
  return response.data;
};
