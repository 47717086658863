import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import ClimateBadge from "../../../../assets/images/stripe_climage_badge.svg";
import StripeWordmarkBlurple from "../../../../assets/images/stripe_wordmark_blurple.svg";
import MobileDonationSection from "../MobileDonationSection/MobileDonationSection";
import { useSelector } from "react-redux";
import { selectCheckout } from "../../slices/checkoutSlice";
import ProgressBar from "../../../../components/ProgressBar/ProgressBar";

const BillingAndPayment: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { billingEmail } = useSelector(selectCheckout);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL || "http://localhost:3000/registration/post-payment",
        receipt_email: billingEmail,
      },
    });

    if (error) {
      setErrorMessage(error.message || "Payment failed.");
    } else {
      console.log("Payment succeeded!");
    }
  };

  return (
    <form
      className="flex flex-col justify-center items-center w-full p-4 sm:p-10"
      onSubmit={handleSubmit}
    >
      {/* Payment Element */}
      <div className="mb-4 w-full sm:w-3/4 pt-20 md:pt-0 lg:pt-0">
        <ProgressBar currentStep={3} />
      </div>

      <div className="mb-4 w-full sm:w-3/4">
        <MobileDonationSection />
      </div>

      {/* Address Element */}
      <div className="mb-4 w-full sm:w-3/4">
        <AddressElement
          options={{
            mode: "billing",
            allowedCountries: ["US", "CA"],
          }}
        />
      </div>

      {/* Payment Element */}
      <div className="mb-4 w-full sm:w-3/4">
        <PaymentElement />
      </div>

      {/* Buy Now Button */}
      <button
        className="w-full sm:w-3/4 bg-primary text-white py-2 rounded hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
        disabled={!stripe}
        type="submit"
      >
        Buy Now
      </button>

      {errorMessage && <div className="text-red-500 mt-2">{errorMessage}</div>}

      {/* Contribute Text and Image */}
      <div className="flex items-center mt-6 w-full sm:w-3/4">
        <img alt="Climate Badge" className="w-8 h-8 mr-2" src={ClimateBadge} />
        <p className="text-sm text-gray-700">
          <span className="font-bold">
            National Society of Collegiate Scholars
          </span>{" "}
          will <span className="font-bold"> contribute </span> a portion of your
          purchase to removing CO₂ from the atmosphere.
        </p>
      </div>

      {/* Links Section */}
      <div className="mt-4 w-full sm:w-3/4 flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 text-xs text-gray-400">
        {/* Powered by Stripe */}
        <a
          className="flex items-center"
          href="https://stripe.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span>Powered by</span>
          <img
            alt="Powered by Stripe"
            className="h-5 w-auto ml-1"
            src={StripeWordmarkBlurple}
          />
        </a>

        {/* Divider */}
        <div className="hidden md:block w-px h-5 bg-gray-300 mx-4"></div>

        {/* Terms and Privacy Links */}
        <div className="flex space-x-4">
          <a
            className="hover:underline"
            href="https://stripe.com/legal/consumer"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms
          </a>
          <a
            className="hover:underline"
            href="https://stripe.com/privacy"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy
          </a>
        </div>
      </div>
    </form>
  );
};

export default BillingAndPayment;
