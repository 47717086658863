import React from "react";

interface NSCSCheckboxProps {
  id: string;
  name: string;
  label: React.ReactNode;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: string;
  className?: string; // Add this prop
}

const NSCSCheckbox: React.FC<NSCSCheckboxProps> = ({
  id,
  name,
  label,
  checked,
  onChange,
  error,
  className, // Destructure the new prop
}) => {
  return (
    <div className={className}>
      {" "}
      {/* Apply className here */}
      <div className="flex items-start mb-4">
        <input
          checked={checked}
          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <label className="ml-2 block text-sm text-gray-900" htmlFor={id}>
          {label}
        </label>
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default NSCSCheckbox;
