import React from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import NSCSLogoShieldWhite from "../../../../assets/images/nscs_logo_shield_white.svg";
import TOLALogoShieldWhite from "../../../../assets/images/tola_logo_shield_white.svg";

interface MobileOrderHeaderSummaryProps {
  showOrderSummary: boolean;
  toggleOrderSummary: () => void;
  totalDueToday: number;
}

const MobileOrderSummaryHeader: React.FC<MobileOrderHeaderSummaryProps> = ({
  showOrderSummary,
  toggleOrderSummary,
  totalDueToday,
}) => {
  const isTola = process.env.REACT_APP_TOLA === "true";
  return (
    <div
      className="md:hidden flex items-center justify-between p-4 bg-primary fixed top-0 left-0 right-0 z-50"
      style={{ height: "64px" }} // Adjust the height as needed
    >
      {/* Logo */}
      <img
        alt="NSCS Logo"
        className="w-10 h-auto mr-4"
        src={isTola ? TOLALogoShieldWhite : NSCSLogoShieldWhite}
      />

      {/* Toggle Button */}
      <button
        className="flex items-center text-white font-medium"
        onClick={toggleOrderSummary}
      >
        {showOrderSummary ? (
          <>
            Hide order summary <FaCaretUp className="ml-2" />
          </>
        ) : (
          <>
            Show order summary <FaCaretDown className="ml-2" />
          </>
        )}
      </button>

      {/* Total Amount */}
      <div className="text-white font-medium ml-auto">
        ${totalDueToday.toFixed(2)}
      </div>
    </div>
  );
};

export default MobileOrderSummaryHeader;
