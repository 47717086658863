import axios from "../axiosInstance";
import {
  SubmitStudentDetailsRequest,
  SubmitStudentDetailsResponse,
} from "./types";

export const submitStudentDetails = async (
  uuid: string,
  requestData: SubmitStudentDetailsRequest,
): Promise<SubmitStudentDetailsResponse> => {
  const response = await axios.post<SubmitStudentDetailsResponse>(
    `/api/public/join/${uuid}/submit-student-details`,
    requestData,
  );
  return response.data;
};
