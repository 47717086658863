import React from "react";
import Header from "../../../../components/Header/Header";
import { Helmet } from "react-helmet-async";
import GetStartedForm from "../GetStartedForm/GetStartedForm";

const GetStarted: React.FC = () => {
  const isTOLA = process.env.REACT_APP_TOLA === "true";

  return (
    <>
      <Helmet>
        <title>Get Started - National Society of Collegiate Scholars</title>
      </Helmet>

      <div
        className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
        style={{
          backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
        }}
      >
        <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl">
          <Header />
          <GetStartedForm isTOLA={isTOLA} />
        </div>
      </div>
    </>
  );
};

export default GetStarted;
