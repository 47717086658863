import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { createUpgradeCharge } from "../../../../api/createUpgradeCharge/createUpgradeChargeApi";
import ConfettiAnimation from "../ConfettiAnimation/ConfettiAnimation";
import { FaCheckCircle } from "react-icons/fa";
import Header from "../../../../components/Header/Header";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { RootState } from "../../../../app/rootReducer";
import { fetchUpgradeDetails } from "../../slices/upgradeDetailsSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UpgradeChargeResponse } from "../../../../api/createUpgradeCharge/types";

const Upgrade: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    loading,
    error,
    upgradeOption,
    isEligibleForUpgrade,
    isSelfNomination,
    features,
    congratulationsHeader,
    congratulationsSubheader,
    upgradeHeader,
    upgradeSubheader,
  } = useSelector((state: RootState) => state.upgradeDetails);

  const params = new URLSearchParams(location.search);
  const paymentIntentId = params.get("payment_intent");

  // New state for handling submission status
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const joinSessionUuid = localStorage.getItem("joinSessionUuid");

    if (joinSessionUuid) {
      dispatch(fetchUpgradeDetails(joinSessionUuid));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  // Redirect if user is not eligible for upgrade
  useEffect(() => {
    if (!loading && !isEligibleForUpgrade) {
      if (isSelfNomination) {
        navigate("/registration/upload-transcript");
      } else {
        navigate("/registration/congratulations");
      }
    }
  }, [loading, isEligibleForUpgrade, isSelfNomination, navigate]);

  const handleUpgrade = async () => {
    try {
      setSubmitting(true); // Set submitting state to true
      const joinSessionUuid = localStorage.getItem("joinSessionUuid");

      if (!joinSessionUuid) {
        alert("No active join session found.");
        setSubmitting(false);
        return;
      }

      if (!paymentIntentId) {
        alert("Payment intent ID is missing.");
        setSubmitting(false);
        return;
      }

      const response: UpgradeChargeResponse = await createUpgradeCharge(
        joinSessionUuid,
        {
          paymentIntent: paymentIntentId,
        },
      );

      const { status } = response.data;

      if (status === "succeeded") {
        if (isSelfNomination) {
          navigate("/registration/upload-transcript");
        } else {
          navigate("/registration/congratulations");
        }
      } else {
        alert("Upgrade failed!");
      }
    } catch (error) {
      console.error("Error processing upgrade charge:", error);
      alert("An error occurred during the upgrade process.");
    } finally {
      setSubmitting(false); // Reset submitting state
    }
  };

  const handleSkip = () => {
    if (isSelfNomination) {
      navigate("/registration/upload-transcript");
    } else {
      navigate("/registration/congratulations");
    }
  };

  if (error) {
    return (
      <div
        className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
        style={{
          backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
        }}
      >
        <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl relative">
          <Header />
          <div className="text-center">
            <p>Error: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Upgrade Membership</title>
      </Helmet>
      <div
        className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
        style={{
          backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
        }}
      >
        <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl relative">
          {!loading && <ConfettiAnimation trigger={true} />}
          <Header />

          <div className="text-center mb-4">
            <h1 className="text-2xl font-bold text-primary mb-4">
              {loading ? <Skeleton width={200} /> : congratulationsHeader}
            </h1>
            <p className="text-xl">
              {loading ? <Skeleton width={300} /> : congratulationsSubheader}
            </p>
          </div>

          <div className="p-8">
            <h2 className="text-2xl font-bold text-primary mb-6 text-center">
              {loading ? <Skeleton width={250} /> : upgradeHeader}
            </h2>
            <p className="text-center text-sm font-semibold mb-6">
              {loading ? <Skeleton width={350} /> : upgradeSubheader}
            </p>

            <ul className="space-y-8">
              {loading
                ? Array.from({ length: 3 }).map((_, index) => (
                    <li className="flex items-start" key={index}>
                      <div className="mr-3 mt-1 w-6 h-6 flex-shrink-0">
                        <Skeleton circle height={24} width={24} />
                      </div>
                      <div className="flex-1">
                        <Skeleton height={16} />
                      </div>
                    </li>
                  ))
                : features.map((feature) => (
                    <li className="flex items-start" key={feature.id}>
                      <FaCheckCircle className="text-primary mr-3 mt-1 w-6 h-6 flex-shrink-0" />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: feature.description,
                        }}
                      />
                    </li>
                  ))}
            </ul>

            <div className="mt-8 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
              {loading ? (
                <>
                  <Skeleton height={40} width={100} />
                  <Skeleton height={40} width={150} />
                </>
              ) : (
                <>
                  <button
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={handleSkip}
                    type="button"
                  >
                    Skip
                  </button>
                  <button
                    className={`bg-primary text-white px-6 py-3 rounded hover:bg-primary-dark transition ${
                      submitting ? "opacity-75 cursor-not-allowed" : ""
                    }`}
                    disabled={submitting}
                    onClick={handleUpgrade}
                  >
                    {submitting ? "Upgrading..." : "Upgrade Now"}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade;
