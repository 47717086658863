import axios from "../axiosInstance";
import { SetDonationRequest, SetDonationResponse } from "./types";

export const setDonation = async (
  joinSessionUuid: string,
  requestData: SetDonationRequest,
): Promise<SetDonationResponse> => {
  const response = await axios.post<SetDonationResponse>(
    `/api/public/join-session/${joinSessionUuid}/set-donation`,
    requestData,
  );
  return response.data;
};
