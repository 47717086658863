import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import Header from "../../../components/Header/Header";
import { uploadTranscript } from "../../../api/uploadTranscript/uploadTranscriptApi";
import { useNavigate } from "react-router-dom";

const SelfNomTranscriptUpload: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{ name: string; size: number }>
  >([]);
  const [currentUpload, setCurrentUpload] = useState<{
    file: File | null;
    uploading: boolean;
    progress: number;
    error: string | null;
  }>({
    file: null,
    uploading: false,
    progress: 0,
    error: null,
  });

  const joinSessionUuid = localStorage.getItem("joinSessionUuid") || "";
  const navigate = useNavigate();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const fileToUpload = acceptedFiles[0];
        setCurrentUpload({
          file: fileToUpload,
          uploading: true,
          progress: 0,
          error: null,
        });

        const formData = new FormData();
        formData.append("file", fileToUpload);

        uploadTranscript(joinSessionUuid, formData, (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0; // Handle undefined `total`
          setCurrentUpload((prevState) => ({
            ...prevState,
            progress: percentCompleted,
          }));
        })
          .then((response) => {
            if (response.success) {
              setUploadedFiles((prevFiles) => [
                ...prevFiles,
                {
                  name: fileToUpload.name,
                  size: fileToUpload.size,
                },
              ]);
              setCurrentUpload({
                file: null,
                uploading: false,
                progress: 0,
                error: null,
              });
            } else {
              setCurrentUpload({
                file: null,
                uploading: false,
                progress: 0,
                error: "Failed to upload file.",
              });
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            setCurrentUpload({
              file: null,
              uploading: false,
              progress: 0,
              error: "Failed to upload file.",
            });
          });
      }
    },
    [joinSessionUuid],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/*": [".jpg", ".jpeg", ".png"],
      "application/msword": [".doc", ".docx"],
    },
    maxFiles: 1,
  });

  const handleContinue = () => {
    navigate("/registration/congratulations");
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen py-10 px-5"
      style={{
        backgroundImage: "linear-gradient(135deg, #A99268 50%, #981B1E 50%)",
      }}
    >
      <div className="w-full max-w-4xl px-4 py-10 bg-white rounded-lg shadow-xl relative">
        <Header />
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-primary mb-2">
            Upload Transcript
          </h2>
          <p className="text-sm text-gray-500">
            Drag and drop or select a file to upload your transcript.
          </p>
        </div>

        <div
          {...getRootProps()}
          className={`p-10 border-dashed border-2 rounded-lg text-center cursor-pointer ${
            isDragActive ? "border-indigo-600" : "border-gray-300"
          }`}
        >
          <input {...getInputProps()} />
          <FaCloudUploadAlt className="text-primary text-4xl mb-3" />
          <p className="text-gray-600">
            {isDragActive
              ? "Drop the file here ..."
              : "Drag & drop a file here, or click to select a file"}
          </p>
        </div>

        {currentUpload.uploading && currentUpload.file && (
          <div className="mt-6">
            <h4 className="text-lg font-semibold mb-2">
              Uploading: {currentUpload.file.name}
            </h4>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-indigo-600 h-2.5 rounded-full"
                style={{ width: `${currentUpload.progress}%` }}
              ></div>
            </div>
          </div>
        )}

        {currentUpload.error && (
          <div className="mt-4 p-3 rounded-lg text-center bg-red-100 text-red-700">
            {currentUpload.error}
          </div>
        )}

        {uploadedFiles.length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-semibold mb-2">Uploaded Files:</h4>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li className="text-gray-700" key={index}>
                  {file.name} - {(file.size / 1024).toFixed(2)} KB
                </li>
              ))}
            </ul>
          </div>
        )}

        {uploadedFiles.length > 0 && (
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              className="mt-6 px-3 py-2 rounded-lg bg-primary text-white hover:bg-primary-light"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelfNomTranscriptUpload;
